import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { css } from 'styled-components'
import Img from 'gatsby-image'

import Layout from '../../components/Layout'

import SEO from '../../components/SEO'
import GetInTouch from '../../components/GetInTouch'

import {
  FAQSection,
  RecentWorkSection,
  HowItWorksSection,
  MaterialsSection,
  UseCaseSection
} from '../../components/Section'

import ButtonGroup from '../../components/ButtonGroup'
import HeroFullBackground from '../../components/HeroFullBackground'

import { media } from '../../utils/media';
import { SectionSelector } from '../../components/SectionSelector'

const HorizontalCard = (props) => {
  const {
    title,
    description,
    image,
    isReversed
  } = props

  return (
    <section
      css={css`
        display: grid;
        grid-gap: 1rem;
        padding: 0 1rem;
        margin-bottom: 2rem;
        ${media.desktop`
          grid-gap: 0;
          grid-template-columns: 1fr 1fr;
          height: 50vh;
          padding: 0;
          margin-bottom: 0;
        `}
    `}>
      {image && <Img fluid={image.fluid} alt={image.title} />}
      <div css={css`
        ${media.desktop`
          grid-column: ${isReversed ? 1 : 2};
          grid-row: 1;
          align-self: center;
          padding: 2rem;
          max-width: 600px;
          margin: auto;
        `}

        ${media.ultrahd`
          max-width: ${props => props.theme.sizes.maxWidthCentered};
        `}
      `}>
        <h3 css={css`
          margin-bottom: 1rem;
          font-weight: 500;
          font-size: 1.5rem;
          color: ${props => props.theme.colors.black};
          text-align: center;

          ${media.desktop`
            font-size: 2rem;
          `}
        `}>{title}</h3>
        <div
          css={css`
            line-height: 1.8;
            color: ${props => props.theme.colors.black}f4;
            text-align: center;
            ${media.desktop`
              font-size: 1.2rem;
            `}
          `}
          dangerouslySetInnerHTML={{ __html: description }}/>
      </div>
    </section>
  )
}


const ServiceLaserCutting = ({ data }) => {
  
  const {
    hero,
    name,
    metaTitle,
    metaDescription,
    slug,
    typeform,
    cardSections,
    gallery,
    howItWorks,
    materials,
    useCases,
    frequentlyAskedQuestions,
    sections,
  } = data.contentfulService

  const postNode = {
    title: metaTitle || `${name} - Service | NYC | PrintAWorld`,
    metaDescription: metaDescription,
  }

  return (
    <div>
      <Layout>
        <Helmet>
          <title>{postNode.title}</title>
        </Helmet>
        <SEO
          postNode={postNode}
          pagePath={`services/${slug}`}
          customTitle
          pageSEO
        />
        <HeroFullBackground
          hero={hero}
          buttonGroup={
            <ButtonGroup typeform={typeform} />
          }
        />
        <RecentWorkSection gallery={gallery} />
        <MaterialsSection materials={materials} />
        {sections && <SectionSelector sections={sections} />}
        {useCases && <UseCaseSection useCases={useCases} />}
        {cardSections &&
          cardSections.map((card, index) => {
            return (
              <HorizontalCard
                key={card.id}
                title={card.title}
                description={card.description.childMarkdownRemark.html}
                image={card.media}
                isReversed={index % 2 !== 0}
              />
            )
          })}
        <HowItWorksSection steps={howItWorks} />
        <FAQSection faqs={frequentlyAskedQuestions} />
        <GetInTouch />
      </Layout>
    </div>
  )
}

export const laserCuttingQuery = graphql`
         query serviceLaserCuttingQuery {
           contentfulService(slug: { eq: "laser-cutting" }) {
             name
             slug
             typeform
             metaTitle
             metaDescription {
               internal {
                 content
               }
             }
             materials {
               ...MaterialCardFragment
             }
             hero {
               ... on Node {
                 ... on ContentfulHeroTextOnly {
                   ...HeroTextOnly
                 }
                 ... on ContentfulHero {
                   ...HeroFragment
                 }
               }
             }
             howItWorks {
               ...HowItWorksCardFragment
             }
             gallery {
               title
               images {
                 ...RecentWorkImages
               }
             }
             frequentlyAskedQuestions {
               id
               question
               answer {
                 childMarkdownRemark {
                   html
                 }
               }
             }
             cardSections {
               title
               id
               media {
                 fluid(quality: 100) {
                   ...GatsbyContentfulFluid_withWebp
                 }
               }
               description {
                 childMarkdownRemark {
                   html
                 }
               }
             }
             sections {
               ...PageSectionFragment
             }
           }
           allContentfulBlog(filter: { categories: { eq: "Laser Cutting" } }) {
             edges {
               node {
                 ...BlogCardFragment
                 categories
               }
             }
           }
         }
       `

export default ServiceLaserCutting
