import React from 'react'
import { css } from 'styled-components'
import { space } from '../utils/tokens'

import { CallButton, QuoteButton } from '../components/Button'

const ButtonGroup = ({
  typeform
}) => (
  <div
    css={css({
      display: `grid`,
      gridGap: `${space[2]}px`,
    })}
  >
    <QuoteButton url={typeform} />
    <CallButton text="CALL US: 1 (929) 213-9604" white full />
  </div>
)

export default ButtonGroup