import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { css } from 'styled-components'

import { QuoteButton } from '../components/Button'

import {
  mediaQueries,
  space,
  breakpoints,
  fontSizes,
  colors,
  mainOffset,
} from '../utils/tokens'

const HeroFullBackground = ({
  hero,
  featuredCompanies,
  typeform,
  buttonGroup,
}) => {

  const showTypeformButton = () => {
    return (
      <div
        css={css({
          display: `flex`,
          flexDirection: `column`,
          alignItems: `center`,
        })}
      >
        <QuoteButton
          url={typeform}
          css={css({
            width: `auto`,
          })}
        >
          {hero.callToActionText || `START YOUR PROJECT`}
        </QuoteButton>
        <span
          css={css({
            display: `block`,
            maxWidth: `150px`,
            marginTop: `${space[2]}px`,
            fontStyle: `italic`,
            textAlign: `center`,
            fontSize: fontSizes[1],
            color: colors.white,
          })}
        >
          Talk to a real person. No signup required.
        </span>
      </div>
    )
  }

  return (
    <section
      css={{
        [mediaQueries.lg]: {
          overflow: `hidden`,
          position: `relative`,
          padding: `${space[5]}px 0 ${space[5]}px`,
          minHeight: `calc(100vh - ${mainOffset})`,
        },
      }}
    >
      <div
        css={css({
          maxWidth: breakpoints.xxl,
          margin: `0 auto`,
          display: `flex`,
          justifyContent: `flex-start`,
          padding: `${space[6]}px ${space[3]}px`,
          position: `relative`,
          [mediaQueries.lg]: {
            padding: `0 ${space[4]}px 0`,
            position: `initial`,
          },
        })}
      >
        <div
          css={css({
            display: `flex`,
            flexDirection: `column`,
            alignItems: `flex-start`,
            maxWidth: `${breakpoints.sm}`,
            backgroundColor: colors.lightBase,
            padding: `${space[4]}px`,
            zIndex: 1,
            [mediaQueries.lg]: {
              display: `flex`,
              flexDirection: `column`,
              alignItems: `flex-start`,
              maxWidth: `${breakpoints.sm}`,
              backgroundColor: colors.lightBase,
              padding: `${space[5]}px`,
            },
          })}
        >
          <h1
            css={css({
              textTransform: `uppercase`,
              letterSpacing: `1.5px`,
              marginBottom: `${space[2]}px`,
              color: colors.lightGray,
            })}
          >
            {hero.subtitle && (
              <span
                css={css({
                  fontSize: fontSizes[2],
                  display: `block`,
                  color: colors.primaryBrand,
                  [mediaQueries.lg]: {
                    fontSize: fontSizes[2],
                  },
                })}
              >
                {hero.subtitle.internal.content}&nbsp;
              </span>
            )}
            <span
              css={css({
                fontSize: fontSizes[4],
                fontWeight: `bold`,
                [mediaQueries.lg]: {
                  fontSize: fontSizes[4],
                },
              })}
            >
              {hero.title}
            </span>
          </h1>
          {hero.tagline && (
            <div
              dangerouslySetInnerHTML={{
                __html: hero.tagline.childMarkdownRemark.html,
              }}
              css={css({
                marginTop: `${space[2]}px`,
                marginBottom: `${space[4]}px`,
                fontSize: fontSizes[3],
                lineHeight: 1.2,
                color: `${colors.lightGray}f4`,
              })}
            />
          )}
         {typeform && showTypeformButton()}
         {buttonGroup && buttonGroup}
          {featuredCompanies && (
            <div
              css={css({
                marginTop: `${space[5]}px`,
              })}
            >
              <div
                css={css({
                  display: `grid`,
                  gridGap: `${space[4]}px`,
                  gridTemplateColumns: `repeat(3, 80px)`,
                  justifyContent: `center`,
                  alignItems: `center`,
                })}
              >
                {featuredCompanies.map((company, id) => {
                  return (
                    <div key={id}>
                      <img
                        src={company.file.url}
                        alt={company.title}
                        css={css({
                          filter: `grayscale(1)`,
                          opacity: 0.75,
                          width: `auto`,
                          margin: `auto`,
                        })}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>
        {hero.backgroundPhoto && (
          <div
            css={css({
              position: `absolute`,
              top: 0,
              left: 0,
              width: `100%`,
              height: `100%`,
              [mediaQueries.lg]: {
                zIndex: -1,
                height: `100vh`,
                overflow: `hidden`,
                marginBottom: 0,
              },
            })}
          >
            <Img
              fluid={hero.backgroundPhoto.fluid}
              alt={hero.backgroundPhoto.title}
              css={css({
                position: `absolute !important`,
                width: `100%`,
                top: 0,
                left: 0,
                '> img': {
                  objectFit: `cover`,
                  objectPosition: `50% 50%`,
                }
              })}
            />
          </div>
        )}
      </div>
    </section>
  )
}

export default HeroFullBackground

HeroFullBackground.propTypes = {
  hero: PropTypes.object,
  featuredCompanies: PropTypes.arrayOf(PropTypes.object),
  typeform: PropTypes.string.isRequired,
}
